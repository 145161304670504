<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    >
      <template #title-right>
        <v-layout align-center>
          <v-spacer />

          <span
            class="vbt-margin-r-10"
            style="font-size: 17px;"
          >
            All accounts
          </span>

          <v-blacker-switch
            color="info"
            class="custom-search-toggle"
            @change="getAccounts"
          />
        </v-layout>
      </template>
    </vbt-search>

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      title="Invoices"
    >
      <ir-invoices-list :items="invoices" />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { InvoicesApiService } from '../_services/invoices.api.service';

import { IrInvoicesList } from '../_components';

const dataLoadingOptions = { getterName: 'getInvoices' };
const paginationOptions = { itemsName: 'invoices' };

const searchOptions = {
  initialSearchDataStates: ['orderId', 'accountId', 'dates'],
  customTypes: {
    accountId: 'number',
    dates: 'array',
  },
};

export default {
  name: 'IrInvoices',

  components: {
    IrInvoicesList,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {

      invoices: [],
    };
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions']),

    searchFieldsConfig() {
      return [
        {
          value: 'orderId',
          label: 'Order ID',
          type: 'text',
          flex: 'sm3',
        },
        {
          value: 'accountId',
          items: this.accountsOptions,
          label: 'Account',
          type: 'combobox',
          flex: 'sm3',
        },
        {
          value: 'number',
          label: 'Number',
          type: 'number',
          flex: 'sm3',
        },
        {
          value: 'dates',
          label: 'Date Range',
          type: 'daterange',
          flex: 'sm3',
        },
      ];
    },
  },

  created() {
    this.getAccounts();
  },

  methods: {
    ...mapActions('accounts', {
      getAccounts(dispatch, isAllAccounts = false) {
        dispatch('getAccounts', { activeOnly: !isAllAccounts });
      },
    }),

    getInvoices({ dates, ...params }) {
      return this.wrapToLoadingFn({
        req: InvoicesApiService.getInvoices.bind({}, {
          to: dates && dates.length ? `${dates[1]} 23:59:59` : null,
          from: dates && dates.length ? dates[0] : null,
          ...params,
        }),
        loadingFlagName: 'isLoading',
        onSuccess: r => this.$set(this, 'invoices', r),
      });
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
